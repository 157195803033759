<script lang="ts">
  import Base from '@factry/base'
  import DowntimeManager from '@factry/downtime-manager'
  import EquipmentManager from '@factry/equipment-manager'
  import MaterialManager from '@factry/material-manager'
  import OperationsManager from '@microbrewery/operations-manager'
  import OrderManager from '@factry/order-manager'
</script>

<Base
  options={[
    OperationsManager,
    EquipmentManager,
    DowntimeManager,
    MaterialManager,
    OrderManager,
  ]}
/>
